@media (min-resolution: 144dpi) and  (min-width: 992px) {
    .App-box-header{
        & h3{
          font-size: 14px;
        }
      }
}
@media (max-width: 1250px) {
    .App-header-menu-wrap{
        margin-left: 0;
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 100%;
        right: -100%;
        background-color: #2f363e;
        padding: 20px 20px;
        & .ant-form{
            flex-direction: column;
        }
        & .App-header-menu{
            flex-direction: column;
        }
        & .App-header-button{
            margin-left: 0;
            margin-top: 20px;
        }        
        & .ant-select.ant-select-in-form-item{
            width: 100% !important;
            max-width: 400px;
        }
        & .ant-btn-primary{
            width: 400px;
        }
    }
    .header-mobile-menu {
        width: 26px;
        height: 20px;
        cursor: pointer;
        margin-right: 20px;
        position: absolute;
        top: 1.6rem;
        right: 0rem;
        z-index: 100;    
        em {
          display: block;
          width: 100%;
          height: 2px;
          background: #fff;
          margin-top: 6px;
          transition: all .3s;
          transition-delay: .2s;
        }
        :first-child {
          margin-top: 0;
        }
        &.open {
          height: auto;
          em {
            background-color: #fff !important;
            &:nth-child(1) {
              transform: translateY(4px) rotate(45deg);
            }
            
            &:nth-child(2) {
              transform: translateY(-4px) rotate(-45deg);
            }
          }
        }
        &.open + .header-menu{
            transform: translateX(0%);
            & .header-rightline{
                display: none;
            }
            & li::after{
                transform: scaleX(100%);           
            }        
        }
        &.open + .App-header-menu-wrap{
            right:0;
        }
    }
    .reset-button{
        margin-right: 50px;
    }
}
@media (max-width: 991px) {
    .time-slider{
        & .ant-slider-dot:nth-child(even){
            display: none;
        }
        & .ant-slider-mark-text:nth-child(even){
            display: none;
        }
    }
}
@media (max-width: 767px) {
    .load-data{
        flex-direction: column;
        
        & .load-data-logo{
            width: 100%;
            text-align: center;
            & img{
                max-width: 60%;
            }
            & .text{
                font-size: 20px;
                margin-top: 30px;             
            }
        }
        & .load-data-img{
            max-width: 80vw;
        }
    }
    .time-zone{
        flex-wrap: wrap;
    }
    .time-slider{
        width: 100%;
    }
}
@media (max-width: 575px) {
    .App-main{
        padding: 20px 16px;
    }
    //node
    .node-energySource{
        width: 80px;
        height: 80px;
        font-size: 14px;
    }
    .node-taipower{
        width: 60px;
    }
    .node-ess{
        width: 45px;
    }
    .node-solar{
        width: 60px;
    }
    .node-load{
        width: 60px;
    }
    .node-all{
        & .title{
            display: block;
        }
    }
    .number-wrap .number{
        font-size: 18px;
        display: block;
    }
    .App-box-header h3{
        font-size: 14px;
        line-height: 1.1;
    }
    .node-all .text .big{
        font-size: 20px;
    }
    .App-main-kv-charts{
        height: 315px;
      }
    .time-zone{
        gap: 8px;
    }
    .App-main .ant-row + .ant-row{
        margin-top: 12px;
    }
}
